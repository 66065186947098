<template>
  <template v-for="(mulItem, mulItemIndex) in mulItems">
    <div>
      <HeadingMultiItem
          :title="toUpper(mulItem.heading)"
          :itemIndex="mulItemIndex"
          @addButtonClicked="addItem"
      >
      </HeadingMultiItem>
      <template v-if="totalShowAbleItems(mulItem) > 0" v-for="(item, itemIndex) in mulItem.items">
        <div class="col-xs-12 col-md-12 mb-75" v-if="item.show">
          <div class="row">
            <div class="col-xs-3 col-sm-3 d-flex">
              <img :src="item.logo !== ''?item.logo:'/app-assets/images/institute.jpg'" class="img-fluid align-middle p-4 contact-image-mobile">
            </div>
            <div class="col-xs-9 col-sm-9">
              <div class="row">

                <!-- Generate All Fields of Items -->
                <template v-for="(field,fieldIndex) in item.fields">
                  <div class="col-xs-12 col-md-12">
                    <label class="form-label">{{ field.title }}</label>
                    <input
                        :class="{'is-invalid' : false}"
                        type="text"
                        class="form-control rounded-0"
                        :placeholder="field.placeholder?field.placeholder:`Enter ${field.title}`"
                        v-model="mulItems[mulItemIndex].items[itemIndex].fields[fieldIndex].value"
                        disabled
                    >
                  </div>
                </template>
                <div class="col-xs-12 col-md-12"><br></div>

              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="col-xs-12 col-md-12 mb-75">
          <div class="empty-item">
            <h4>No Items Found</h4>
            <h5>Click Plus Icon Top To Add Item</h5>
          </div>
        </div>
      </template>
    </div>
  </template>
  <MultiItemGroupModal :heading="modal.heading" :fields="modal.fields" :show="modal.show" @CloseModal="modal.show = false" @AddGroup="AddToGroupItem"></MultiItemGroupModal>
</template>

<script>
import {has, countBy, debounce, forEach} from 'lodash'
import HeadingMultiItem from "@/components/molecule/contact/HeadingMultiItem";
import {handleInitData} from "@/services/modules/contact";
import MultiItemGroupModal from "@/components/atom/Contact/MultiItemGroupModal";

export default {
  name: "MultiItemGroup",
  emits: ['dataChanged'],
  components: {
    HeadingMultiItem: HeadingMultiItem,
    MultiItemGroupModal: MultiItemGroupModal,
  },
  data: () => ({
    modal: {
      show: false,
      multiItemIndex: 0,
      itemIndex: 0,
      fields:[],
      heading: 'Default Heading'
    },

    mulItems: [],
  }),

  watch: {
    mulItems: {
      handler: function () {
        this.$emit('dataChanged', this.mulItems);
      },
      deep: true
    }
  },

  methods: {
    toUpper(string) {
      if (!!!string) return ''
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    addItem(index) {
      let firstItem = this.mulItems[index].items[0]
      this.modal.heading = this.mulItems[index].heading

      let newFields = firstItem.fields.map((field) => {
        return {
          ...field,
          value: '',
        }
      })
      this.modal.fields = newFields
      this.mulItems[index].items.push({
        ...firstItem,
        fields: newFields,
        show: false,
      })

      this.modal.multiItemIndex = index;
      this.modal.itemIndex = this.mulItems[index].items.length - 2;
      this.modal.show = true
    },

    AddToGroupItem(form){
      forEach(this.mulItems[this.modal.multiItemIndex].items[this.modal.itemIndex].fields, field => {
        field.value = form[field.key]
      })
      this.modal.show = false
      this.mulItems[this.modal.multiItemIndex].show = true
      this.mulItems[this.modal.multiItemIndex].items[this.modal.itemIndex].show = true
    },
    removeItemFromMultiItemForm(key, index) {
      // this.contactProfile.multiFormItems[key].splice(index, 1)
    },
    totalShowAbleItems(mulItem){
      return countBy(mulItem.items, obj => !!obj.show)["true"]
    }
  },
  mounted() {
    this.mulItems = this.groupItemsInitData;
  },

  setup() {
    const {groupItemsInitData} = handleInitData()
    return {
      has,
      debounce,
      countBy,
      groupItemsInitData
    }
  },
}
</script>

<style scoped>
.form-control:disabled {
  background-color: white;
}

.empty-item{
  border: 2px dotted #7367f0;
  width: 100%;
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>
