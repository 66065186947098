<template>
  <div>
    <div class="card">
      <div class="card-header pb-0">
        <h1>Search & Add To Contact</h1>
      </div>
      <div class="card-content pb-1">
        <div class="card-body">
          <div class="row pb-2">
            <div class="col-xs-12 col-sm-12">
              <div class="mb-xs-3">
                <SearchWithCollapse
                    :suggest="shouldSuggest"
                    :suggestData="search.searchContacts"
                    :onRecordClicked="searchRecordClicked"
                    :onEntered="searched"
                    :onSearch="searching"
                ></SearchWithCollapse>
              </div>
            </div>
          </div>

          <div class="row" v-if="shouldShowSuggestTable">
            <div class="col-sm-12 col-xs-12">
              <div class="table-responsive">
                <table class="table">
                  <tbody>
                  <tr v-for="rec in search.searchContacts">
                    <td>{{ rec.name??'N/A' }}</td>
                    <td>{{ rec.email??'N/A' }}</td>
                    <td>{{ rec.phone??'N/A' }}</td>
                    <td class="d-flex">
                      <button
                          class="btn btn-icon btn-success waves-effect waves-float waves-light rounded-0" title="Add To Contact"
                          v-on:click="this.addToMyContact(rec.id)"
                      >
                        <i class="fas fa-plus"></i>
                      </button>

                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {inject} from "vue";
import handleContact, {handleHelpers} from "@/services/modules/contact";
import SearchWithCollapse from "@/components/atom/Contact/SearchWithCollapse";
import {has} from "lodash";

export default {
  name: "AddContactByUserSearch",
  components:{
    SearchWithCollapse: SearchWithCollapse,
  },
  watch: {
    searchable: {
      handler(){
        this.search.searchContacts = []
      },
      deep: true,
    }
  },
  data: () => ({
    searchable:{
      name: '',
    },
    search:{
      searching: false,
      searchContacts: [],
      suggest: false
    },
    contacts: [],
  }),
  computed:{
    shouldSuggest(){
      if(this.search.searchContacts.length < 1) return false;

      return this.search.searching && this.search.suggest
    },
    shouldShowSuggestTable(){
      if(this.search.searchContacts.length < 1) return false;

      return this.search.searching && !this.search.suggest
    }
  },
  methods: {
    searchRecordClicked(id){
      this.addToMyContact(id)
    },
    searching(query){
      this.search.searching = true;
      this.search.suggest = true;
      this.searchable.name = query
      this.searchUsers();
    },
    searched(query){
      this.search.searching = true;
      this.search.suggest = false;
    },
    getQuery(){
      let item, query = '';
      for (item in this.searchable)
      {
        if(!!this.searchable[item])
        {
          query = query.includes('&')?`${query}&${item}=${this.searchable[item]}`:`${item}=${this.searchable[item]}`
        }
      }

      return query
    },
    async searchUsers(){
      if(!this.searchable.name) {
        this.search.suggest = false
        return;
      }
      let query = this.getQuery();
      if(!!!query) return this.showError("Search field is empty")

      try {
        this.loading = true;
        let res = await this.getUsersBySearch(query)
        if (!res.status) {
          this.search.suggest = false
          this.showError(res.message)
          this.search.searchContacts = []
        }
        if (res.status) {
          this.showSuccess(res.message)
          this.formatSearchableContacts(res)
        }
      } catch (err) {
        this.search.searchContacts = []
        if (!err.response) {
          return this.showError('Something is wrong.!!')
        }
        if (err.response.data.message) {
          return this.showError(err.response.data.message)
        }
        if (err.response.statusText) {
          return this.showError(err.response.statusText)
        }
      } finally {
        this.loading = false
      }
    },

    async addToMyContact(userId){
      try {
        let res;
        this.loading = true;
        if(this.isCompanyModule()) res = await this.addToCompanyContact(this.$route.params.companyId, {user_id: userId})
        else res = await this.addToUserContact({user_id: userId})

        if (!res.status) {
          this.showError(res.message)
        }
        if (res.status) {
          this.showSuccess(res.message)

          if(this.isCompanyModule()) this.$router.push({name: 'company.contacts.details', params: this.generateTargetCompanyRoute(this.$route, 'contactId', res.data.contact_id)})
          else this.$router.push({name: 'contact.profile.details', params:{contactId: res.data.contact_id}})
        }
      } catch (err) {

        if(err.response)
        {
          if (!!err.response.data.message) {
            return this.showError(err.response.data.message)
          }

          if (err.response.statusText) {
            return this.showError(err.response.statusText)
          }

        }else{
        }
      } finally {
        this.loading = false
      }
    },

    formatSearchableContacts(res){
      if(has(res, 'data.data')){
        return this.search.searchContacts = res.data.data.map((item) => {
          return {
            contact_profile_id: has(item, 'contact_profile.id')?item.contact_profile.id:null,
            id: item.id,
            name: item.name,
            phone: item.phone,
            email: item.email,
            photo: item.photo,
          }
        })

      }

      return this.search.searchContacts = [];
    },
  },

  setup() {
    const showError = inject('showError');
    const showSuccess = inject('showSuccess');

    const {
      getUsersBySearch,
      addToUserContact,
      addToCompanyContact,
      loading,
    } = handleContact()

    const {isCompanyModule, generateTargetCompanyRoute} = handleHelpers()

    return {
      getUsersBySearch,
      addToUserContact,
      addToCompanyContact,
      isCompanyModule,
      generateTargetCompanyRoute,
      loading,
      showError,
      showSuccess
    }
  }
}
</script>

<style scoped>


</style>
