<template>
  <div class="col-xs-12 col-md-12 mb-0 pb-0">
    <div class="d-flex justify-content-between align-items-center">
      <h4 class="mb-0">{{ title }}</h4>
      <button
          type="button"
          class="rounded-0 ms-1 btn btn-icon btn-primary waves-effect waves-float waves-light ms-auto"
          title="Add more items"
          v-on:click="addItem"
      >
        <i class="fas fa-plus"></i>
      </button>
    </div>
    <hr>
  </div>
</template>

<script>
import {has} from 'lodash'
export default {
  name: "HeadingMultiItem",
  props:['title', 'itemIndex'],
  methods:{
    addItem(){
      this.$emit('addButtonClicked', this.itemIndex)
    }
  },
  setup(){
    return {
      has
    }
  },
}
</script>

<style scoped>

</style>
